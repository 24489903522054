//Copy from generated scube api, to prevent circular referencies
class TenantInfoResourceModel {
  name?: string;
  description?: string;
  tenantType?: string;
  memberName?: string;
  targetName?: string;
  targetType?: string;
  tenantUri?: string;
  id?: string;
  createdDate?: string;
  updatedDate?: string;
  eTag?: string;
}

declare global {
  interface Window {
    tenantType: string;
    language: string;
    theme: string;
    scubeUserContext: any;
  }
}

export type scubeContextType = {
  scubeUserContext: any;
  language: string;
  chatLanguage: string;
  region: string;
  theme: string;
  tenantsList: any[];
  partnersList: any[];
  amyDisabledPages: string[];
  fbac: any;
  isTest: boolean;
  userEmail: string | undefined;
  userKey: string | undefined;
  tenantType: string | undefined;
  subscriptionType: string | undefined;
  appType: string | undefined;
  isAuth: boolean;
  isAmyMuted: boolean;
  TenantUriName: string;
  TenantName: string;
  TenantDisplayName: string;
  defaultTenant: any | undefined;
  isEmailVerified: boolean;
  getApiPath: (apiUrl: string) => string;
  adjustLink: (apiUrl: string, params?: {} | undefined) => string;
  adjustLink2: (apiUrl: string, entities?: adjustLinkEntitiesParamsType) => string;
  isAmyAudioSuspended: boolean;
  t2atoken?: string | undefined;
  isUnpaid: boolean;
  isExpired: boolean;
  isTerminated: boolean;
  terminationExpiresInDays?: number;
  isFailed: boolean;
  getUrlByClientType: (clientUri: string, clientType: string, relativePath: string) => string;
  getMyPersonalityLinkInLite: () => string;
  getMyPersonalityInitialSessionLinkInLite: () => string;
  getLiteClientUrlSegment: () => string;
  getLiteClientUrl: (relativePath: string) => string;
  adjustLiteClientUrlSegment: (relativePath: string) => string;
  getAbsoluteUrlForTenant: (relativePath: string) => string;
  getWorkspaceApiPath: (url: string) => void;
  getRouteSegmentPath: (url: string) => string;
  onSetTenant: undefined | ((tenantName: string, tenantCallback: (() => void) | undefined) => void);
  setTenant: (tenantName: string) => void;
  onRedirectToTenant: undefined | ((tenantUri: string) => void);
  redirectToTenant: (tenantUri: string) => void;
  onSignOutForGuestSession: undefined | ((guestUrl: string) => void);
  signOutForGuestSession: (guestUrl: string) => void;
  shortDateFormat: string;
  dateWithTimeFormat: string;
  PartnerUriName: string;
  PartnerSubscriptionUriName: string;
  PartnerSubscriptionTypeName: string;
  tenantInfo?: TenantInfoResourceModel | undefined;
  isAmySpeaking: boolean;
  UserDisplayName?: string;
  partnerTypes?: string[];
  themeId?: string;
  userActionPlayAudio: (e:any, data:any) => void;
  userActionPlayAudioFx: (data:any) => void;
  toggleMuteUserAudio: (toggle:boolean|undefined) => void;
  toggleMuteUserAudioFx: (toggle:boolean) => void;
  userAudioSource: any;
  userActionPauseToggleAudio:(pause:boolean)=>void;
  userActionPauseToggleAudioFx:(pause:boolean)=>void;
};

const scubeContext: scubeContextType = {
  ...window.scubeUserContext,
  language: window.language ?? 'cs',
  region: window.language ?? 'cs',
  theme: window.theme ?? 'd',
  tenantsList: [],
  amyDisabledPages: [],
  fbac: {},
  isTest: false,
  userEmail: '',
  userKey: undefined,
  tenantType: window.tenantType ?? 'undefined',
  appType: undefined,
  isAuth: false,
  isAmyMuted: true,
  isAmyAudioSuspended: false,
  isEmailVerified: false,
  isUnpaid: false,
  isAmySpeaking: true,
  themeId: undefined,
};

scubeContext.getApiPath = function (apiUrl: string) {
  //ui react/:tenant/somthing

  // /:tenant/somthing
  const result = '/' + scubeContext.TenantUriName + '/' + apiUrl;

  return result;
};

//getClientUrlSegment

scubeContext.getUrlByClientType = function (clientUri: string, clientType: string, relativePath: string) {
  let uriClientType = undefined;
  if (clientType === 'c' || clientType === undefined) {
    uriClientType = 'c';
  }
  if (clientType === 'companyCandidate') {
    uriClientType = 'cc';
  }
  if (clientType === 'companyClient') {
    uriClientType = 'ce';
  }
  if (clientType === 'psychologistClient') {
    uriClientType = 'pc';
  }
  if (clientType === 'physicalClient') {
    uriClientType = 'pp';
  }
  if (clientType === 'externalCompanyClient') {
    uriClientType = 'ecc';
  }
  if (clientType === 'externalPsychologistClient') {
    uriClientType = 'epc';
  }

  let result = `/${uriClientType}/${clientUri}`;

  if (!relativePath) {
    return result;
  }

  relativePath = relativePath.startsWith('/') ? relativePath : `/${relativePath}`;

  result = result + relativePath;

  return result;
};

export type adjustLinkEntitiesParamsType = {
  clientid?:string;
  clienttype?:string;
  teamType?:string;
  teamid?:string;
  teamUri?:string;
  externalCompanyUri?:string;
  externalOrderType?:string;
  externalOrderUri?:string;
}

scubeContext.adjustLink2 = function (relativePath: string, entities?: adjustLinkEntitiesParamsType) {

  return scubeContext.adjustLink(relativePath, entities);
}

const urlPatternRegex = /:(?<name>\w+)(\(|\w+|\||\))*\//gi;

scubeContext.adjustLink = function (relativePath: string, params?: {} | undefined) {
  if (relativePath.includes(':tenantId')) {
    relativePath = relativePath.replace(':tenantId', scubeContext.TenantUriName);
  }

  if (relativePath.includes(':partnerId')) {
    relativePath = relativePath.replace(':partnerId', scubeContext.PartnerUriName);
  }

  if (relativePath.includes(':subscriptionType') && scubeContext.PartnerSubscriptionTypeName) {
    relativePath = relativePath.replace(':subscriptionType', scubeContext.PartnerSubscriptionTypeName);
  }

  if (relativePath.includes(':subscriptionId') && scubeContext.PartnerSubscriptionUriName) {
    relativePath = relativePath.replace(':subscriptionId', scubeContext.PartnerSubscriptionUriName);
  }

  if (relativePath.includes(':publicUserId') && scubeContext.userKey) {
    relativePath = relativePath.replace(':publicUserId', scubeContext.userKey as string);
  }

  const foundGroups = [...relativePath.matchAll(urlPatternRegex)];
  for (let matchItem of foundGroups) {
    const paramName = (matchItem.groups as any).name;
    const text = matchItem[0];

    //console.log({name:paramName, text:text ,matchItem:matchItem, val:(params as any)[paramName]});

    relativePath = relativePath.replace(text, (params as any)[paramName] + '/');
  }

  return relativePath;
};

scubeContext.adjustLiteClientUrlSegment = function (relativePath: string, params?: {} | undefined) {
  if (relativePath.includes(':publicUserId')) {
    relativePath = relativePath.replace(':publicUserId', scubeContext.userKey as string);
  }

  const foundGroups = [...relativePath.matchAll(urlPatternRegex)];
  for (let matchItem of foundGroups) {
    const paramName = (matchItem.groups as any).name;
    const text = matchItem[0];

    //console.log({name:paramName, text:text ,matchItem:matchItem, val:(params as any)[paramName]});

    relativePath = relativePath.replace(text, (params as any)[paramName] + '/');
  }

  return relativePath;
};

scubeContext.getMyPersonalityLinkInLite = function () {
  const result =
    window.location.protocol + `//` + window.location.host + `/protected/section/${scubeContext.userKey}/overview`;
  return result;
};

scubeContext.getMyPersonalityInitialSessionLinkInLite = function () {
  const result =
    window.location.protocol + `//` + window.location.host + `/protected/section/${scubeContext.userKey}/mysession`;
  return result;
};

//obsolete
scubeContext.getLiteClientUrlSegment = function () {
  const result = '/section/' + scubeContext.userKey;
  return result;
};
//obsolete
scubeContext.getLiteClientUrl = function (relativePath: string) {
  const result = '/section/' + scubeContext.userKey + (relativePath.startsWith('/') ? '' : '/') + relativePath;
  return result;
};
//obsolete
scubeContext.getAbsoluteUrlForTenant = function (relativePath: string) {
  const result = '/' + scubeContext.TenantUriName + relativePath;

  return result;
};

scubeContext.getWorkspaceApiPath = function (url: string) {
  //react/:tenant/somthing
};
//obsolete
scubeContext.getRouteSegmentPath = function (url: string) {
  return '/' + url;
};

scubeContext.onSetTenant = undefined;
scubeContext.setTenant = function (tenantName: string) {
  if (scubeContext.onSetTenant) scubeContext.onSetTenant(tenantName, undefined);
};

scubeContext.onRedirectToTenant = undefined;
scubeContext.redirectToTenant = function (tenantUri: string) {
  if (scubeContext.onRedirectToTenant) scubeContext.onRedirectToTenant(tenantUri);
};
scubeContext.tenantsList = [];
scubeContext.partnersList = [];

scubeContext.onSignOutForGuestSession = undefined;
scubeContext.signOutForGuestSession = function (guestUrl: string) {
  if (scubeContext.onSignOutForGuestSession) scubeContext.onSignOutForGuestSession(guestUrl);
};

scubeContext.userActionPlayAudio = function(e:any, data:any){
  if(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  // if(scubeContext.userAudioSource)
  // if(scubeContext.userAudioSource)
  // {
  //   scubeContext.userAudioSource.play();
  // }
  if(scubeContext.userActionPlayAudioFx){
    scubeContext.userActionPlayAudioFx(data);
  }
}

scubeContext.toggleMuteUserAudio = function(toggle:boolean){
  if(scubeContext.toggleMuteUserAudioFx){
    const curVal = toggle === undefined ? true : toggle;
    scubeContext.toggleMuteUserAudioFx(curVal);
  }
}

scubeContext.userActionPauseToggleAudio = function(pause:boolean){
  if(scubeContext.userActionPauseToggleAudioFx){
    const curVal = pause === undefined ? true : pause;
    scubeContext.userActionPauseToggleAudioFx(pause);
  }
}

export default scubeContext;
