import {cameraFace, sceneCameraType} from './cameras';
import {effectExitWaiting, effectIntro} from './effects';
import {sceneEventType, sceneEventTypes} from '../sceneEvents';
import scubeContext from '../../../core/scubeContext';
import sceneContext, {sceneSequenceType, sceneType} from '../sceneEngine';

type sceneChatContextType = {
  sleep: boolean;
  socialization: boolean;
  walkTimes: number;
  idle: boolean;
  firstPass: boolean;
  idleTimes: number;
  lastIdleAnimation: string;
};

export const sequenceHelp: sceneSequenceType | any = {
  onStart(context: sceneContext & sceneChatContextType) {
    context.walkTimes = 2;
    context.idle = true;
    context.sleep = false;

    context.startCamera(cameraFace);
    context.startEffect(effectIntro);
    context.fadeToAnimation('Walking');

    //context.setSize(600,200);
  },

  onFinish(context: sceneContext) {
    //stop events
    //stop animations
  },

  selectIdle(exceptKey: string) {
    const animations = ['Idle', 'Tablet', 'Idle', 'Bashful', 'Idle'];
    //let animations = ['Bashful', 'Bashful', 'Bashful', 'Bashful', 'Bashful'];
    const filteredAnimations = animations.filter((key) => key != exceptKey);
    const order = Math.floor(Math.random() * Math.floor(filteredAnimations.length));
    return filteredAnimations[order];
  },

  selectIntermezzo() {
    let animations = [];
    if (scubeContext.tenantType === 'physical') {
      animations = [
        'Secret',
        'Yawn',
        'Thankful',
        'Reacting',
        'Looking',
        'Jump',
        'Climbing',
        'SillyDancing',
        'RumbaDancing',
        'Looking2',
        'Macarena',
        'Salsa',
        'Pointing',
        'LookingBehind',
        'No',
        'Yes',
      ];
    } else {
      animations = ['Secret', 'Thankful', 'Reacting', 'Looking', 'Looking2', 'Pointing', 'LookingBehind', 'No', 'Yes'];
    }
    let order = Math.floor(Math.random() * Math.floor(animations.length));
    return animations[order];
  },

  onAnimationFinished(context: sceneContext & sceneChatContextType, animationName: string) {
    //console.log('onAnimationFinished:' + animationName);

    // waiting
    if (context.sleep) {
      // DAVID STOP
      return;
    }

    // intro walking
    if (animationName === 'Walking') {
      if (context.walkTimes > 0) {
        //console.log('onAnimationFinished: context.walkTimes > 0' + animationName);

        context.walkTimes -= 1;
      } else {
        //console.log('onAnimationFinished: else context.walkTimes > 0' + animationName);

        // run first idle
        context.fadeToAnimation('Greeting');
        context.idle = false;
      }
    } else {
      if (context.idle && (context.idleTimes == 0 || !context.idleTimes)) {
        //console.log('onAnimationFinished: context.idle' + animationName);

        let anim = this.selectIntermezzo();
        context.fadeToAnimation(anim);
        if (anim === 'Yes') {
          context.startEffect(effectExitWaiting);
        }
        context.idle = false;
      } else {
        //console.log('onAnimationFinished: else context.idle' + animationName);
        //console.log({ walkTimes: context.walkTimes });

        if (context.idleTimes > 0) {
          context.idleTimes--;
          //context.fadeToAnimation(context.lastIdleAnimation);
          //console.log({ action: 'decreaseidle', idleTimes: context.idleTimes });
        } else {
          context.idle = true;
          context.idleTimes = 3;
          context.lastIdleAnimation = this.selectIdle(context.lastIdleAnimation);
          context.fadeToAnimation(context.lastIdleAnimation);
          //console.log({ action: 'gotoIdle', anim: context.lastIdleAnimation, idleTimes: context.idleTimes });
        }
      }
    }
  },

  onEffectFinished(context: sceneContext & sceneChatContextType, effect: sceneEventType) {
    if (effect === (effectExitWaiting as any) && !context.sleep) {
      context.startEffect(effectIntro);
    }
  },

  onCameraFinished(context: sceneContext, camera: sceneCameraType) {},

  onEvent(context: sceneContext & sceneChatContextType, sceneEvent: sceneEventType) {
    // console.log("chat event received");
    //console.log(sceneEvent);
    if (
      sceneEvent.eventType === sceneEventTypes.ReceivedChatButtonSet ||
      sceneEvent.eventType === sceneEventTypes.ReceivedQuestionnaireAmyText ||
      sceneEvent.eventType === sceneEventTypes.ReceivedChatQuestion
    ) {
      if (!context.walkTimes) {
        context.idleTimes = 0;

        let animations = ['HandRising', 'Waving', 'Waving2'];
        let order = Math.floor(Math.random() * Math.floor(animations.length));
        context.fadeToAnimation(animations[order]);
      }
    }

    if (sceneEvent.eventType === sceneEventTypes.SentChatAnswer) {
      //context.startEffect(effectExitWaiting);
    }

    if (sceneEvent.eventType === sceneEventTypes.ReceivedAmyEvent) {
      if (sceneEvent.eventSubType === 'end-session') {
        context.sleep = true;
        context.startEffect(effectExitWaiting);
      }
    }
  },
  isRecurrent: true,
};

export const sceneHelpFace: sceneType = {
  id: 'sceneHelpFace',
  name: 'help', //development field only
  modelName: 'amy_psychologist.glb',
  onStart: (context: sceneContext) => {
    context.startSequence(sequenceHelp as any);
  },
  onStop: (context: sceneContext) => {},

  onFinish: (context: sceneContext) => {},
  onSequenceFinished: (sequenceName: string) => {},
  onEffectFinished: (effectName: string) => {},
  onEvent: (context: sceneContext, sceneEvent: sceneEventType) => {},
  sequences: [sequenceHelp],
  amySettings: {
    particlesHitArea: false,
    particlesRandom: 30.0, //1.5
    particlesDepth: 2, //6 //6.06
    particlesXYMove: true, //true
    particlesBW: false,
    particlesSquare: false,
    particlesSize: 0.5, //4.0, //3.2
    particlesSmooth: 0.9, // //0.3
    particlesShift: 0,
    matrix: 128, //128, // 100
    planeRotate: 0,
  },
};
