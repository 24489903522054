export enum sceneEventTypes {
  None = 'None',
  ReceivedChatQuestion = 'ReceivedChatQuestion',
  ReceivedChatButtonSet = 'ReceivedChatButtonSet',
  ReceivedChatText = 'ReceivedChatText',
  ReceivedQuestionnaireAmyText = 'ReceivedQuestionnaireAmyText',
  ReceivedQuestionnaireOptions = 'ReceivedQuestionnaireOptions',
  ReceivedAmyEvent = 'ReceivedAmyEvent',

  SentChatAnswer = 'SentChatAnswer',

  AmyScene = 'AmyScene',
}

export class sceneEvent {
  eventType: sceneEventTypes;
  eventSubType?: string;
  data?: any;

  constructor(eventType: sceneEventTypes) {
    this.eventType = eventType;
  }
}

export type sceneEventType = {
  eventType: sceneEventTypes,
  eventSubType?: string;
  data?: any;
}