import {TimelineLite} from 'gsap';
import sceneContext from '../sceneEngine';


export type sceneCameraType = {
  onStart: (context: sceneContext) => void;
  onFinish: (context: sceneContext) => void;
};

export const cameraRotate = {
  onStart: (context: sceneContext) => {
    let tl1 = new TimelineLite({
      onComplete: function () {
        context.invokeCameraFinished(cameraRotate);
      },
    });

    let delta = { t: 0.0, zd: 0.0 };
    let fromX = context.amy3DHolder.bufferCamera.position.x;
    let fromZ = context.amy3DHolder.bufferCamera.position.z;
    tl1.fromTo(
      delta,
      {
        t: 0,
        zd: fromZ,
      },
      {
        duration: 10,
        t: 6.28,
        zd: 400,
        onUpdate: function () {
          context.amy3DHolder.bufferCamera.position.x = fromX * Math.cos(delta.t) + delta.zd * Math.sin(delta.t);
          context.amy3DHolder.bufferCamera.position.z = delta.zd * Math.cos(delta.t) - fromX * Math.sin(delta.t);

          context.amy3DHolder.bufferCamera.lookAt(context.amy3DHolder.bufferCamera.lookAtPosition);
        },
      },
      0
    );
  },

  onFinish: (context: sceneContext) => {},
  // params:{
  //   posittions:{time:0, to:17} //startframe
  //  zoom:{time:0, to:17}
  // }
};

export const cameraHalfBody = {
  onStart: (context: sceneContext) => {
    let tl1 = new TimelineLite({
      onComplete: function () {
        context.invokeCameraFinished(cameraHalfBody);
      },
    });
    tl1.to(
      context.amy3DHolder.bufferCamera.position,
      {
        duration: 3,
        ease: 'power3.inOut',
        z: 350,
        x: 0,
      },
      0
    );

    tl1.to(
      context.amy3DHolder.bufferCamera.lookAtPosition,
      {
        duration: 3,
        ease: 'power3.inOut',
        y: 170,
        onUpdate: function () {
          context.amy3DHolder.bufferCamera.lookAt(context.amy3DHolder.bufferCamera.lookAtPosition);
        },
      },
      0
    );
  },
  onFinish: (context: sceneContext) => {},
};

export const cameraRightTopFull = {
  onStart: (context: sceneContext) => {
    let tl1 = new TimelineLite({
      onComplete: function () {
        context.invokeCameraFinished(cameraRightTopFull);
      },
    });
    tl1.to(
      context.amy3DHolder.bufferCamera.position,
      {
        duration: 3,
        ease: 'power3.inOut',
        x: 230,
        z: 460,
      },
      0
    );

    tl1.to(
      context.amy3DHolder.bufferCamera.lookAtPosition,
      {
        duration: 3,
        ease: 'power3.inOut',
        y: 85,
        onUpdate: function () {
          context.amy3DHolder.bufferCamera.lookAt(context.amy3DHolder.bufferCamera.lookAtPosition);
        },
      },
      0
    );
  },
  onFinish: (context: sceneContext) => {},
};

export const cameraRightTop = {
  onStart: (context: sceneContext) => {
    let tl1 = new TimelineLite({
      onComplete: function () {
        context.invokeCameraFinished(cameraRightTop);
      },
    });
    tl1.to(
      context.amy3DHolder.bufferCamera.position,
      {
        duration: 3,
        ease: 'power3.inOut',
        x: 150,
        z: 300,
      },
      0
    );

    tl1.to(
      context.amy3DHolder.bufferCamera.lookAtPosition,
      {
        duration: 3,
        ease: 'power3.inOut',
        y: 120,
        onUpdate: function () {
          context.amy3DHolder.bufferCamera.lookAt(context.amy3DHolder.bufferCamera.lookAtPosition);
        },
      },
      0
    );
  },
  onFinish: (context: sceneContext) => {},
};

export const cameraFace = {
  onStart: (context: sceneContext) => {
    let tl1 = new TimelineLite({
      onComplete: function () {
        context.invokeCameraFinished(cameraFace);
      },
    });
    tl1.to(
      context.amy3DHolder.bufferCamera.position,
      {
        duration: 3,
        ease: 'power3.inOut',
        z: 250,
        x: 20,
      },
      0
    );

    tl1.to(
      context.amy3DHolder.bufferCamera.lookAtPosition,
      {
        duration: 3,
        ease: 'power3.inOut',
        y: 140,
        onUpdate: function () {
          context.amy3DHolder.bufferCamera.lookAt(context.amy3DHolder.bufferCamera.lookAtPosition);
        },
      },
      0
    );
  },

  onFinish: (context: sceneContext) => {},
  // params:{
  //   posittions:{time:0, to:17} //startframe
  //  zoom:{time:0, to:17}
  // }
};


export const cameraAmyScenario = {
  onStart: (context: sceneContext) => {
    let tl1 = new TimelineLite({
      onComplete: function () {
        context.invokeCameraFinished(cameraAmyScenario);
      },
    });
    tl1.to(
      context.amy3DHolder.bufferCamera.position,
      {
        duration: 3,
        ease: 'power3.inOut',
        z: 220,
        x: 40,
      },
      0
    );

    tl1.to(
      context.amy3DHolder.bufferCamera.lookAtPosition,
      {
        duration: 3,
        ease: 'power3.inOut',
        y: 140,
        x: 10,
        onUpdate: function () {
          context.amy3DHolder.bufferCamera.lookAt(context.amy3DHolder.bufferCamera.lookAtPosition);
        },
      },
      0
    );
  },

  onFinish: (context: sceneContext) => {},
  // params:{
  //   posittions:{time:0, to:17} //startframe
  //  zoom:{time:0, to:17}
  // }
};

export const cameraDefault = {
  onStart: (context: sceneContext) => {
    let tl1 = new TimelineLite({
      onComplete: function () {
        context.invokeCameraFinished(cameraDefault);
      },
    });
    tl1.to(
      context.amy3DHolder.bufferCamera.position,
      {
        duration: 3,
        ease: 'power3.inOut',
        z: 550,
        x: 50,
      },
      0
    );
    tl1.to(
      context.amy3DHolder.bufferCamera.lookAtPosition,
      {
        duration: 3,
        ease: 'power3.inOut',
        y: 90,
        onUpdate: function () {
          context.amy3DHolder.bufferCamera.lookAt(context.amy3DHolder.bufferCamera.lookAtPosition);
        },
      },
      0
    );
  },

  onFinish: (context: sceneContext) => {},
};

export const cameraMainIntro = {
  onStart: (context: sceneContext) => {
    let tl1 = new TimelineLite({
      onComplete: function () {
        context.invokeCameraFinished(cameraMainIntro);
      },
    });
    // tl1.to(context.amy3DHolder.camera.rotation, {
    //   duration: 5,
    //   y: context.amy3DHolder.camera.rotation.y + Math.PI * 0.1,
    // });
  },

  onFinish: (context: sceneContext) => {},
};

export const cameraMainBack = {
  onStart: (context: sceneContext) => {
    let tl1 = new TimelineLite({
      onComplete: function () {
        context.invokeCameraFinished(cameraMainBack);
      },
    });
    // tl1.to(context.amy3DHolder.camera.rotation, {
    //   duration: 5,
    //   y: 0,
    // });
  },

  onFinish: (context: sceneContext) => {},
};
