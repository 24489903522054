import Amy3D from '../amy/amyWebGL/amy3D';
import React from 'react';
import {sceneHelp} from '../amy/amyWebGL/scenes/sceneHelp';
import AmyAspectRatio from '../amy/chats/amyAspectRatio';
import {sceneHelpFace} from '../amy/amyWebGL/scenes/sceneHelpFace';
import {useMediaQuery} from '@mui/material';
import {sceneType} from '../amy/amyWebGL/sceneEngine';

declare global {
  interface Window {
    amySmallScreenDimensions: number;
    amyLargeScreenDimensions: number;
    amyScene:string;
  }
}

const WebAmyApp = () => {
  let curScene: sceneType = sceneHelp;
  const isLargeScreen = useMediaQuery('(min-width:576px)');
  const sceneSize = {
    small: window.amySmallScreenDimensions ?? 250,
    large: window.amyLargeScreenDimensions ?? 380,
  };

  const dimensions = {
    width: isLargeScreen ? sceneSize.large : sceneSize.small,
    height: isLargeScreen ? sceneSize.large : sceneSize.small,
    aspectRatio: AmyAspectRatio.Square,
    isDownScaled:false
  };

  if (window.amyScene) {
    if (window.amyScene === 'sceneHelp') {
      curScene = sceneHelp;
    }
    if (window.amyScene === 'sceneHelpFace') {
      curScene = sceneHelpFace;
    }
  }

  return <Amy3D className={undefined} amyScene={curScene} amyModel={'amy_psychologist.glb'} dimensions={dimensions} />;
};

export default WebAmyApp;

