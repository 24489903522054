import {TimelineLite} from 'gsap';
import sceneContext from '../sceneEngine';

export type sceneEffectType = {
  onStart: (context: sceneContext) => void;
  onFinish: (context: sceneContext) => void;
  name: string;
};

const to = (
  context: sceneContext,
  effect: sceneEffectType,
  data: { uSmooth: number; uSize: number; uRandom: number; duration: number; uDepth: number }
) => {
  let tl1 = new TimelineLite({
    onComplete: function () {
      context.invokeEffectFinished(effect);
    },
  });

  // if (data.uSmooth) {
  //   tl1.to(
  //     context.amy3DHolder.particles.object3D.material.uniforms.uSmooth,
  //     {
  //       value: data.uSmooth,
  //       duration: data.duration,
  //     },
  //     0
  //   );
  // }
  // if (data.uDepth) {
  //   tl1.to(
  //     context.amy3DHolder.particles.object3D.material.uniforms.uDepth,
  //     {
  //       value: data.uDepth,
  //       duration: data.duration,
  //     },
  //     0
  //   );
  // }
  // if (data.uSize) {
  //   tl1.to(
  //     context.amy3DHolder.particles.object3D.material.uniforms.uSize,
  //     {
  //       value: data.uSize,
  //       duration: data.duration,
  //     },
  //     0
  //   );
  // }
  // if (data.uRandom) {
  //   tl1.to(
  //     context.amy3DHolder.particles.object3D.material.uniforms.uRandom,
  //     {
  //       value: data.uRandom,
  //       duration: data.duration,
  //     },
  //     0
  //   );
  // }
};

export const effectIntro = {
  onStart: (context: sceneContext) => {
    to(context, effectIntro, { uSmooth: 0.9, uSize: 4.0, uRandom: 3.5, duration: 2, uDepth: 0.1 });
  },

  onFinish: (context: sceneContext) => {},
  // params:{
  //   uRandom:{from:0, to:17}
  //  uSize:{from:0, to:17}
  // }
  name: 'effectIntro',
};

export const effectExit = {
  onStart: (context: sceneContext) => {
    to(context, effectExit, { uSmooth: 0.9, uSize: 0.1, uRandom: 30.0, duration: 2, uDepth: 30 });
  },

  onFinish: (context: sceneContext) => {},
  // params:{
  //   uRandom:{from:0, to:17}
  //  uSize:{from:0, to:17}
  // }

  name: 'effectExit',
};

export const effectExitWaiting = {
  onStart: (context: sceneContext) => {
    to(context, effectExitWaiting, { uSmooth: 0.99, uSize: 0.01, uRandom: 40.0, duration: 2, uDepth: 50 });
  },

  onFinish: (context: sceneContext) => {},
  // params:{
  //   uRandom:{from:0, to:17}
  //  uSize:{from:0, to:17}
  // }
  name: 'effectExitWaiting',
};
